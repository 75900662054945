<template>
  <div class="main p-35">
    <div class="cut_off p-b-10 flex" v-if="isShow">
      <div class="font24 color-ffa flex-1">经络穴位</div>
      <div class="flex-cen flex-1">
        <Input v-model="value" placeholder="搜索关键字...">
          <Icon type="ios-search" slot="prefix"
        /></Input>
        <div style="width: 50px" class="m-l-10">搜索</div>
      </div>
    </div>
    <div v-if="isShow">
      <div>
        <div
          class="flex-between br-bot p-b-13 hand"
          v-for="(item, i) in dataList"
          :key="i"
          @click="item.isOpen = !item.isOpen"
        >
          <div class="m-t-18">
            <div class="font18 color333">{{ item.typeName }}</div>
          </div>
          <div v-if="iconDown">
            <img src="../assets/images/icon_39 (1).png" alt="" />
          </div>
          <div v-else><img src="../assets/images/icon_40.png" alt="" /></div>
          <div class="s_box" v-if="item.isOpen">
            <div
              v-for="(li, i) in item.child"
              :key="i"
              @click="detBtn(li.typeId)"
            >
              <div class="single">{{ li.typeName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShowDetail">
      <div class="text-r font14 color999 hand" @click="returnBtn()">
        返回上一级
      </div>
      <div class="text-c font24 colorff9" v-html="detailShow.title"></div>
      <div v-html="detailShow.content"></div>
    </div>
  </div>
</template>

<script>
import { toolCateApi, contentDetailsApi } from "@/api/cyclo";
export default {
  data() {
    return {
      value: "",
      iconDown: true,
      isBox: false,
      isShow: true,
      dataList: [],
      detailShow: {},
      isShowDetail: false,
    };
  },
  methods: {
    getList() {
      toolCateApi({}).then((res) => {
        let obj = {};
        let arr = [];
        res.data.forEach((item) => {
          obj[item.parentId]
            ? obj[item.parentId].push(item)
            : (obj[item.parentId] = []);
        });
        for (let key in obj) {
          let li = {
            ...obj[key][0].parent,
            child: obj[key],
          };
          li.isOpen = arr.length === 0;
          arr.push(li);
        }
        console.log("ressss", arr);
        this.dataList = arr;
      });
    },
    detBtn(id) {
      contentDetailsApi({ dataId: id, toolCode: "meridians" }).then((res) => {
        this.isShow = false;
        this.isShowDetail = true;
        this.detailShow = res.data;
        console.log("resresres", res);
        console.log("resresres", res);
      });
    },
    // 返回上一层
    returnBtn() {
      this.isShow = true;
      this.isShowDetail = false;
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 695px;
  overflow: auto;
  background: #ffffff;
  .cut_off {
    border-bottom: 1px solid #ffa54b;
  }
  .br-bot {
    border-bottom: 1px solid #e2e2e2;
  }
  .s_box {
    display: flex;
    flex-wrap: wrap;
    .single {
      margin-right: 26px;
      margin-bottom: 5px;
      margin-top: 5px;
      font-size: 14px;
      color: #999999;
      text-align: center;
      line-height: 36px;
      width: 120px;
      height: 36px;
      background: #f9f9f9;
      border-radius: 4px;
    }
  }
}
</style>